<template>
  <v-layout>
    <v-snackbar
      v-model="showSnackbar"
      color="error"
    >
      {{ snackbarText }}
    </v-snackbar>
  </v-layout>
  <div
    v-if="calculatedOptions.length === 0"
    class="tw-text-2xl tw-font-bold tw-mb-8 tw-mt-8"
  >
    To learn about Katalist's plans and your upgrade options, please contact us through support.
  </div>
  <div v-else>
    <div class="pricing-option">
      <template
        v-for="(option, index) in calculatedOptions"
        :key="index"
      >
        <pricing-option
          :option="option"
          :loading="loadingIndex === index"
          :yearly-billing-period="yearlyBillingPeriod"
          @select="(title: PaidPlan) => option.buttonAction(index, title)"
        />
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import tracking from '@/services/tracking'
import { onMounted, ref, computed, PropType, watch } from 'vue'
import { useAppStore } from '@/store/app'
import { useUserStore } from '@/store/user'
import { useCheckoutStore } from '@/store/checkout'

import PricingOption from '@/components/PricingOption.vue'
import { PaywallPrice, PaidPlan } from '@/types'
const appStore = useAppStore()
const userStore = useUserStore()
const checkoutStore = useCheckoutStore()

const selectedTierIndex = ref(-1)
const selectedTier = ref<PaidPlan>('Essential')

const props = defineProps({
  userEmail: {
    type: String,
    required: false
  },
  tier: {
    type: String,
    required: false
  },
  yearlyBillingPeriod: {
    type: Boolean,
    required: true
  },
  trialDisabled: {
    type: Boolean,
    required: false
  },
  currentBillingPeriod: {
    type: String as PropType<'month' | 'year'>,
    required: false
  }
})

// Local error handling properties
const loadingIndex = ref<number>(-1)
const showSnackbar = ref<boolean>(false)
const snackbarText = ref<string>('')

const nonpayingTiers = ['Free', 'Demo', 'Unpaid', 'AppSumo Tier 1', 'AppSumo Tier 2', 'AppSumo Tier 3']

const calculatedOptions = computed(() => {
  return options
    .filter((option) => {
      if (option.title === 'Generative Video Studio' && props.yearlyBillingPeriod) {
        return false
      }
      if (props.yearlyBillingPeriod && props.currentBillingPeriod === 'month' && option.title === props.tier) {
        return true
      }
      return option.visibleForTiers.includes(props.tier!) || userStore.org.cancelled_at
    })
    .map((option) => {
      if (props.yearlyBillingPeriod) {
        return {
          ...option,
          ...option.yearly
        }
      } else {
        return option
      }
    })
    .map((option) => {
      if (props.trialDisabled) {
        return {
          ...option,
          buttonText: 'Upgrade'
        }
      } else {
        return option
      }
    })
})

const options: (PaywallPrice & { visibleForTiers: string[] })[] = [
  {
    visibleForTiers: nonpayingTiers,
    title: 'Essential',
    description: 'For individuals looking to explore Katalist\'s storytelling platform capabilities.',
    price: '$19.00',
    pricePeriod: '/ user / month',
    buttonText: 'Try for Free',
    borderWidth: '2px',
    buttonBorderColor: '#000',
    buttonBackground: '#FFF',
    buttonTextColor: '#000',
    buttonAction: startPaidPlan,
    cancelText: 'Cancel Anytime',
    features: [
      '200 image credits / mo.',
      '5 project limit',
      '1 custom style',
      '1 custom character',
      'AI Script Assistant',
      'Consistent Characters',
      'Voiceover'
    ],
    fontFamily: 'Mulish-Bold',
    label: '',
    discountBackground: '#36c5f0',
    annualPrice: '',
    annualPriceText: '',
    yearly: {
      price: '$19.00',
      annualPrice: '$228',
      annualPriceText: 'Billed Annually'
    },
    newFeatures: []
  },
  {
    visibleForTiers: [...nonpayingTiers, 'Essential'],
    title: 'Pro',
    description: 'For professionals who need advanced features and more flexibility.',
    price: '$39.00',
    pricePeriod: '/ user / month',
    buttonText: 'Try for Free',
    borderWidth: '2px',
    buttonBorderColor: '#000',
    buttonBackground: '#FFF',
    buttonTextColor: '#000',
    buttonAction: startPaidPlan,
    cancelText: 'Cancel Anytime',
    features: [
      '700 image credits / mo.',
      '20 project limit',
      '2 custom styles',
      '10 custom character',
      'AI Script Assistant',
      'Consistent Characters',
      'Voiceover',
      'AI Image Editor',
      'Adobe Premiere Pro Integration'
    ],
    fontFamily: 'Mulish-Bold',
    discountBackground: '#FFC658',
    yearly: {
      price: '$39.00',
      annualPrice: '$468',
      annualPriceText: 'Billed Annually'
    },
    newFeatures: []
  },
  {
    visibleForTiers: [...nonpayingTiers, 'Essential', 'Pro'],
    title: 'Unlimited',
    description: 'For power users and small teams needing unlimited resources.',
    price: '$99.00',
    pricePeriod: '/ month',
    buttonText: 'Try for Free',
    borderWidth: '2px',
    buttonBorderColor: '#000',
    buttonBackground: '#FFF',
    buttonTextColor: '#000',
    buttonAction: startPaidPlan,
    cancelText: 'Cancel Anytime',
    label: 'Popular',
    features: [
      'Unlimited image credits',
      'Unlimited projects',
      'Unlimited custom styles',
      'Unlimited custom characters',
      'AI Script Assistant',
      'Consistent Characters',
      'Voiceover',
      'AI Image Editor',
      'Adobe Premiere Pro Integration',
      'Real-time team collaboration',
      '200+ panel projects',
      '2 users'
    ],
    discountBackground: '#FFC658',
    fontFamily: 'Mulish-Bold',
    yearly: {
      price: '$99.00',
      annualPrice: '$1,188',
      annualPriceText: 'Billed Annually'
    },
    newFeatures: []
  },
  {
    visibleForTiers: [...nonpayingTiers, 'Essential', 'Pro', 'Unlimited'],
    title: 'Enterprise',
    description: 'For large organisations that work at scale.',
    price: 'Contact Us',
    buttonText: 'Book Demo',
    buttonBorderColor: '#000',
    buttonBackground: '#FFF',
    buttonTextColor: '#000',
    buttonAction: getInTouch,
    borderWidth: '2px',
    features: [
      'Unlimited Users',
      'Unlimited video credits',
      'Unlimited projects',
      'Unlimited custom characters',
      '200+ panels per project',
      'Voiceover',
      'ZIP, PPT, Video export',
      'Premiere Pro & FCP Export'
    ],
    newFeatures: [
      'Real-time collaboration',
      'Enterprise Integrations',
      '24/7 Dedicated Support',
      'API Access'
    ],
    generationCredits: 'Scaleable for large orgs',
    discountBackground: '#111',
    fontFamily: 'Mulish-Bold',
    yearly: {}
  }
]

onMounted(async () => {
  if (!appStore.projects) {
    await appStore.getProjects()
  }
})

async function startPaidPlan (index: number, tier: PaywallPrice['title']) {
  if (loadingIndex.value !== -1) {
    return
  }

  if (tier === 'Enterprise') {
    getInTouch()
    return
  }

  selectedTierIndex.value = index
  selectedTier.value = tier
  loadingIndex.value = index

  try {
    // Open the video add-on modal and pass the selected tier
    checkoutStore.openVideoAddOnModal(tier, props.yearlyBillingPeriod)

    const unwatch = watch(() => checkoutStore.videoAddOnModal, (isOpen: boolean) => {
      if (!isOpen) {
        loadingIndex.value = -1
        unwatch()
      }
    })
  } catch (error) {
    loadingIndex.value = -1
    showSnackbar.value = true
    snackbarText.value = 'An error occurred. Please try again later.'
    console.error(error)
  }
}

// handleCheckout function removed as it's now handled directly in the checkout store

function getInTouch () {
  tracking.track('Plan Selected', { 'Plan type': 'Studio', CTA: 'Get in touch' })
  window.location.assign('mailto:sales@katalist.ai&subject=Studio')
}
</script>

<style scoped lang="scss">
@use "@/styles/settings" as *;

$light-pink: #FFF4FA;
$dark-pink: #F2CEE2;

.pricing-option {
  justify-content: center;
  align-items: end;
  gap: 56px;
  display: flex;
  flex-flow: column nowrap;
  padding-top: 30px;
  padding-bottom: 30px;

  @include xl {
    flex-direction: row;
  }
}

</style>
