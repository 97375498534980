import mixpanel from 'mixpanel-browser'
import { User } from '@supabase/supabase-js'
import { Organization } from '@/types'
import { logEvent as statsigLogEvent, installStatsig } from '@/services/statsig'

const INTERNAL_USER_KEY = 'is_internal_user'
const INTERNAL_DOMAIN = '@katalist.ai'

function isInternalUser (email: string): boolean {
  return email.endsWith(INTERNAL_DOMAIN)
}

function setInternalUserFlag (isInternal: boolean) {
  localStorage.setItem(INTERNAL_USER_KEY, JSON.stringify(isInternal))
}

function getInternalUserFlag (): boolean {
  const flag = localStorage.getItem(INTERNAL_USER_KEY)
  return flag ? JSON.parse(flag) : false
}

export default {
  track: async (event: string, properties: Record<string, string | number | object | null | boolean> = {}) => {
    if ('time' in properties) {
      console.warn('Property "time" is not allowed to be tracked by mixpanel. It will be ignored.')
      delete properties.time
    }

    if (process.env.NODE_ENV === 'development' || getInternalUserFlag()) {
      console.log(`[TRACK] ${event}`, properties)
    }

    mixpanel.track(event, properties)
    // @ts-expect-error window has no intercom property
    window.Intercom('trackEvent', event, properties)
    // Log event to Statsig
    await statsigLogEvent(event, undefined, properties as Record<string, string>)
  },
  page: async (name: string, properties: Record<string, string | number | object | null | boolean> = {}) => {
    if ('time' in properties) {
      console.warn('Property "time" is not allowed to be tracked by mixpanel. It will be ignored.')
      delete properties.time
    }

    mixpanel.track_pageview({ page: name })
    // @ts-expect-error window has no intercom property
    window.Intercom('trackEvent', 'page view', { page: name })
    // Log page view to Statsig
    await statsigLogEvent('page_view', name, properties as Record<string, string>)

    if (process.env.NODE_ENV === 'development' || getInternalUserFlag()) {
      console.log(`[PAGE] ${name}`, properties)
    }
  },
  identify: async (user: User, org: Organization) => {
    // Initialize Statsig with user info
    await installStatsig(
      user.id,
      user.email!,
      org.tier!,
      org.ownerEmail!
    )

    const isInternal = isInternalUser(user.email!)
    setInternalUserFlag(isInternal)

    if (process.env.NODE_ENV === 'development' || isInternal) {
      console.log(`[IDENTIFY] ${user.email} from ${org.ownerEmail}`)
    }
  },
  traits: async (id: string, traits: Record<string, string | number> = {}) => {
    mixpanel.people.set(traits)

    if (process.env.NODE_ENV === 'development' || getInternalUserFlag()) {
      console.log(`[TRAITS] ${id}`, traits)
    }
  }
}
