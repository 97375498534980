/* eslint-disable space-before-function-paren */
import type { RunwayUpsellConfig } from '@/types'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useUserStore } from '@/store/user'
import {
  proceedToCheckout,
  activateSubscription as serviceActivateSubscription,
  mapTierForCheckout,
  mapTierForVideoAddonUpsell
} from '@/services/checkout'
import { PaywallPrice, PaidPlan } from '@/types'
import tracking from '@/services/tracking'

export const useCheckoutStore = defineStore('checkout', () => {
  const userStore = useUserStore()
  const activateNowModal = ref(false)
  const payUpModal = ref(false)
  const referralModal = ref(false)
  const runwayUpsellModal = ref(false)
  const videoAddOnModal = ref(false)
  const videoAddOnOnly = ref(true)
  const videoAddOnSelectedPlan = ref<PaidPlan | undefined>(undefined)
  const runwayUpsellConfig = ref<RunwayUpsellConfig>({
    type: 'none',
    action: async () => {
      console.warn('No action implemented')
      return false
    },
    actionText: '',
    loadingText: 'Loading...',
    skipText: 'Skip'
  })
  const selectedYearlyBillingPeriod = ref(false)

  // Service wrapper function
  async function activateSubscription(): Promise<boolean> {
    return serviceActivateSubscription()
  }

  function isTrialEligible(selectedTier?: PaywallPrice['title']): boolean {
    return (
      userStore.org!.tier !== 'Free' &&
      userStore.org.subscription_status === 'registered' &&
      (selectedTier
        ? selectedTier !== 'Generative Video Studio'
        : userStore.org.tier !== 'Generative Video Studio')
    )
  }

  // Enhanced checkout methods
  async function proceedToVideoAddonCheckout(
    customerEmail: string,
    includeVideoAddOn: boolean,
    billingPeriod: 'month' | 'year' = 'month',
    selectedTier?: PaywallPrice['title']
  ): Promise<void> {
    // The selected tier is the tier that the user has selected to upgrade to
    // If no tier is selected, we use the current tier (which is the tier the user is on, and will be upgraded to with a video addon )
    const mappedTier = selectedTier
      ? mapTierForCheckout(selectedTier, includeVideoAddOn)
      : mapTierForVideoAddonUpsell(userStore.org.tier, includeVideoAddOn)

    const trial = selectedTier
      ? isTrialEligible(selectedTier)
      : isTrialEligible()

    if (selectedTier) {
      tracking.track('Plan Selected', {
        'Plan type': selectedTier,
        trial,
        includeVideoAddOn
      })
    } else {
      tracking.track('Add video add on upsell', { 'Plan type': mappedTier })
    }

    return proceedToCheckout({
      customerEmail,
      tier: mappedTier,
      trial,
      billingPeriod
    })
  }

  async function proceedToGenerativeVideoStudioCheckout(
    customerEmail: string,
    billingPeriod: 'month' | 'year' = 'month'
  ): Promise<void> {
    tracking.track('Plan Selected', {
      'Plan type': 'Generative Video Studio',
      trial: false
    })
    return proceedToCheckout({
      customerEmail,
      tier: 'Generative Video Studio',
      trial: false,
      billingPeriod
    })
  }

  async function proceedToIndividualDiscountCheckout(
    customerEmail: string,
    billingPeriod: 'month' | 'year' = 'month'
  ): Promise<void> {
    tracking.track('Claim Individual Discount Clicked')
    return proceedToCheckout({
      customerEmail,
      tier: 'Individual',
      trial: false,
      billingPeriod,
      applyIndividualDiscount: true
    })
  }

  // VideoAddOnModal functions
  function openVideoAddOnModal(plan?: PaidPlan, isYearlyBilling?: boolean) {
    videoAddOnSelectedPlan.value = plan
    selectedYearlyBillingPeriod.value = isYearlyBilling || false
    // If we have a plan, then we're adding an add-on to an existing subscription
    // Otherwise, we're showing the upsell modal and using the existing plan from the user's org
    videoAddOnOnly.value = !plan
    videoAddOnModal.value = true
  }

  function openPayUpModal() {
    payUpModal.value = true
  }

  function closeVideoAddOnModal() {
    // Reset the video add-on modal state
    videoAddOnModal.value = false
    videoAddOnOnly.value = true
    videoAddOnSelectedPlan.value = undefined
  }

  // Used when we're upgrading to a subscription from the pricing list
  async function handlePricingListCheckout(
    includeVideoAddOn: boolean,
    selectedTier?: PaywallPrice['title']
  ): Promise<boolean> {
    const email = userStore.user?.email

    if (!email) {
      return false
    }

    try {
      await proceedToVideoAddonCheckout(
        email,
        includeVideoAddOn,
        selectedYearlyBillingPeriod.value ? 'year' : 'month',
        selectedTier
      )
      return true
    } catch {
      // Error is handled by the service and the component
      return false
    }
  }

  // Used when we're adding a video add-on to an existing subscription (upsell)
  // Billing period is always the same as the user's org
  async function handleVideoAddOnCheckout(
    includeVideoAddOn: boolean
  ): Promise<boolean> {
    if (!includeVideoAddOn) {
      return true
    }

    const email = userStore.user?.email

    if (!email) {
      return false
    }

    try {
      await proceedToVideoAddonCheckout(
        email,
        includeVideoAddOn,
        userStore.org.subscription_billing_period
      )
      return true
    } catch {
      // Error is handled by the service and the component
      return false
    }
  }

  function openRunwayUpsellModal(config: RunwayUpsellConfig) {
    runwayUpsellConfig.value = config
    runwayUpsellModal.value = true
  }

  function closeRunwayUpsellModal() {
    runwayUpsellModal.value = false
  }

  function openActivateNowModal() {
    activateNowModal.value = true
    tracking.track('Activate Now Modal Opened')
  }

  function closeActivateNowModal() {
    activateNowModal.value = false
  }

  return {
    activateNowModal,
    payUpModal,
    referralModal,
    runwayUpsellModal,
    runwayUpsellConfig,
    selectedYearlyBillingPeriod,
    videoAddOnModal,
    videoAddOnSelectedPlan,
    videoAddOnOnly,

    // Service wrapper functions
    activateSubscription,

    // Enhanced checkout methods
    proceedToVideoAddonCheckout,
    proceedToGenerativeVideoStudioCheckout,
    proceedToIndividualDiscountCheckout,

    // VideoAddOnModal functions
    openVideoAddOnModal,
    closeVideoAddOnModal,
    handleVideoAddOnCheckout,
    handlePricingListCheckout,

    openPayUpModal,
    openRunwayUpsellModal,
    closeRunwayUpsellModal,
    openActivateNowModal,
    closeActivateNowModal
  }
})
