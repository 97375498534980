import { Project, Organization, CustomAppearance } from '@/types'
import { toRaw, Ref } from 'vue'
import { cloneDeep, pick } from 'lodash'
import { RealtimeChannel, User } from '@supabase/supabase-js'
import supabase from '@/supabase'
import { getUser } from '@/auth'
import { FeatureFlags } from '@/featureFlags'
import { TypedEmitter } from 'tiny-typed-emitter'
import { getAxios } from '@/services/axiosHelper'
import { useToast } from '@/shadcn/components/ui/toast'
import { AxiosError } from 'axios'

export const dbUpdateTicker = new TypedEmitter<{
  orgDbUpdate:() => void
}>()

export function getCurrentTimestamp () {
  return new Date()
}

const toast = useToast()

export function cleanProject (project: Project) {
  const projectCopy: Pick<Project, 'frames' | 'characters' | 'scenes' | 'name'> = pick(cloneDeep(toRaw(project)), ['frames', 'characters', 'scenes', 'name'])

  projectCopy.frames.forEach((frame) => {
    frame.frameOptions?.forEach(fo => {
      delete fo.inpaint
      delete fo.skeletonPoints
      delete fo.crop
    })
  })
  return projectCopy
}

export async function commitProject (project: Project, lastUpdated: Date) {
  const projectCopy = cleanProject(project)

  const axios = await getAxios()

  console.debug('commitProject', project.id, lastUpdated.getTime())
  try {
    await axios.put(`/api/frames/${project.id}`, {
      frames: projectCopy.frames,
      characters: projectCopy.characters,
      scenes: projectCopy.scenes,
      lastUpdated: lastUpdated.toISOString()
    })
  } catch (error) {
    if (error instanceof AxiosError) {
      if (error.response?.status === 400) {
        toast.toast({
          variant: 'destructive',
          title: 'Error updating project',
          description: 'Please reload the page to avoid using your work. If this persists, please contact support.'
        })
      }
    }
    throw new Error('Error updating project')
  }
}

export async function deleteCustomAppearance (appearanceId: string) {
  const { error } = await supabase.from('custom_appearances').delete().eq('id', appearanceId)

  if (error) {
    console.log(error)
    throw new Error('Error deleting character')
  }
}

async function getOrgId (user: User) {
  // retreive the user document from supabase

  const { data, error } = await supabase
    .from('users')
    .select()
    .eq('email', user.email)

  if (error) {
    console.log(error)
    throw new Error('Error fetching user')
  }

  if (!data) {
    throw new Error('User not found')
  }
  return data[0].orgId
}

export async function getOrg (user: User) {
  const { data } = await supabase
    .from('users')
    .select('email, survey_answers, organizations(*)')
    .eq('email', user.email)
    .single()

  if (!data) {
    throw new Error('Org not found')
  }

  const org = {
    ...data.organizations,
    users: [
      {
        email: data.email,
        survey_answers: data.survey_answers
      }
    ]
  }
  return org as unknown as Organization
}

export function subscribeToOrgChanges (orgId: string, org: Ref<Organization | null>): RealtimeChannel {
  return supabase
    .channel('org-changes')
    .on(
      'postgres_changes',
      {
        event: 'UPDATE',
        schema: 'public',
        table: 'organizations',
        filter: 'id=eq.' + orgId
      },
      (payload) => {
        org.value = {
          ...payload.new as Organization,
          users: org.value?.users || []
        }
      }
    )
    .subscribe()
}

export async function getCustomAppearances (): Promise<CustomAppearance[]> {
  const user = await getUser()
  const orgid = await getOrgId(user)
  const { data, error } = await supabase.from('custom_appearances').select('*').eq('owner_id', orgid).order('created')
  if (error) {
    console.log(error)
    throw new Error('Error fetching custom appearances')
  }

  return data.map(customAppearance => {
    return {
      description: customAppearance.description,
      extractedImage: customAppearance.extracted_image,
      id: customAppearance.id,
      name: customAppearance.name,
      style: customAppearance.style
    }
  })
}

export async function getProject (projectId: string): Promise<Project> {
  const axios = await getAxios()
  const response = await axios.get(`/api/projects/${projectId}`)
  const project = response.data

  return project
}

export async function getSingleProject (projectId: string): Promise<Project> {
  const axios = await getAxios()
  const response = await axios.get(`/api/projects/${projectId}`)
  return response.data
}

export async function getProjects (): Promise<Project[]> {
  const axios = await getAxios()

  try {
    const response = await axios.get('/api/projects')
    const projects = response.data

    return projects
  } catch (error) {
    console.error('Error fetching projects:', error)
    throw new Error('Error fetching projects')
  }
}

export async function deleteProject (projectId: string): Promise<void> {
  await supabase.from('projects').update({ deleted: true }).eq('id', projectId)
}

export async function getGlobalFeatures (): Promise<FeatureFlags> {
  const { data, error } = await supabase.from('features').select('*')

  if (error) {
    throw new Error('Error fetching global features' + error.message)
  }

  return data.reduce((acc, feature) => {
    acc[feature.name] = feature.enabled
    return acc
  }, {}) as FeatureFlags
}
