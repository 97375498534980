import { defineStore, acceptHMRUpdate } from 'pinia'
import { getProjects as getProjectsFromDb, deleteProject as deleteProjectFromDb, getProject, getSingleProject } from '@/services/databaseHelper'
import { computed, ref, watch } from 'vue'
import { getAxios } from '@/services/axiosHelper'
import { Scene, Project, ProjectSettings, CurrentProject, Character, VoiceoverVoice, Frame } from '@/types'
import hash from 'object-hash'
import { useEditorStateStore } from '@/store/editorState'
import { useAppearanceStore } from '@/store/appearance'
import { curry, debounce, DebouncedFunc } from 'lodash'

export const useAppStore = defineStore('app', () => {
  const projects = ref<Project[] | null>(null)
  const currentProjectId = ref<string | null>(null)
  const promisedTimeout = ref<null | NodeJS.Timeout>(null)
  const voiceoverAllVoices = ref<VoiceoverVoice[] | null>(null)
  const editorStateStore = useEditorStateStore()
  const appearanceStore = useAppearanceStore()
  const getVoiceoverVoices = async (): Promise<void> => {
    const axios = await getAxios()
    const response = await axios.get('/api/tts/get-voices')
    voiceoverAllVoices.value = response.data
  }

  const clearDraftProject = {
    id: 'draft',
    projectSettings: {
      style: 'cinematic',
      aspectRatio: 'landscape'
    },
    lastUpdated: '',
    created: '',
    deleted: false,
    name: 'New Project',
    frames: [],
    custom_appearances: [],
    scenes: [],
    characters: []
  }
  const draftProject = ref(JSON.parse(JSON.stringify(clearDraftProject)) as CurrentProject)

  const isNewProject = ref<boolean>(false)

  const setCurrentProjectIdToDraft = () => {
    currentProjectId.value = 'draft'
  }

  const internalCurrentProject = ref<Project | null>(null)
  const setCurrentProjectId = async (newProjectId: string, isNew: boolean = false) => {
    currentProjectId.value = newProjectId

    try {
      internalCurrentProject.value = await getProject(newProjectId)

      if (!internalCurrentProject.value) {
        console.error(`Project with ID ${newProjectId} not found`)
        return
      }

      editorStateStore.initializeProjectState(internalCurrentProject.value)
      appearanceStore.getStyleAvatars()

      isNewProject.value = isNew
    } catch (error) {
      console.error(`Error setting current project ID to ${newProjectId}:`, error)
    }
  }

  const clearDraft = () => {
    draftProject.value = JSON.parse(JSON.stringify(clearDraftProject)) as CurrentProject
  }

  const currentProject = computed(() => {
    if (currentProjectId.value === 'draft') {
      return draftProject.value
    }
    if (!internalCurrentProject.value) {
      return draftProject.value
    }
    return internalCurrentProject.value
  })

  const maybeCurrentProject = computed(() => {
    return internalCurrentProject.value
  })

  const updateProject = async (projectId: string, { characters, scenes, name }: { characters?: Character[], scenes?: Scene[], name?: string }) => {
    const axios = await getAxios()
    await axios.put(`/api/projects/${projectId}`, {
      characters,
      scenes,
      name
    })
  }

  const createFrame = async (projectId: string, frame: Frame) => {
    const axios = await getAxios()
    await axios.post(`/api/projects/${projectId}/frames`, frame)
  }

  const updateFrame = async (projectId: string, frameId: string, frame: Frame) => {
    const axios = await getAxios()
    await axios.put(`/api/projects/${projectId}/frames/${frameId}`, frame)
  }

  const debouncedUpdateFramesMap: { [key: string]: DebouncedFunc<(frame: Frame) => Promise<void>> } = {}

  const debounceUpdateFrame = (frameId: string) => {
    if (!debouncedUpdateFramesMap[frameId]) {
      const debouncedUpdateFrame = debounce(curry(updateFrame)(maybeCurrentProject.value!.id!, frameId), 1000)
      debouncedUpdateFramesMap[frameId] = debouncedUpdateFrame
      console.log('debouncedUpdateFrame', debouncedUpdateFrame)
    }
    return debouncedUpdateFramesMap[frameId]
  }

  watch(() => maybeCurrentProject.value?.frames?.reduce((acc: { [key: string]: string }, frame) => {
    acc[frame.id] = hash(frame)
    return acc
  }, {}), (frames, oldFrames) => {
    if (oldFrames && frames) {
      for (const frame of Object.entries(oldFrames)) {
        if (frame[1] !== frames[frame[0]]) {
          const frameToUpdate = currentProject.value.frames.find((fr) => fr.id === frame[0])
          if (frameToUpdate) {
            debounceUpdateFrame(frameToUpdate.id)(frameToUpdate)
          }
        }
      }
    }
  }, { deep: true })

  watch([
    () => maybeCurrentProject.value?.characters,
    () => maybeCurrentProject.value?.scenes,
    () => maybeCurrentProject.value?.name
  ], ([newCharacters, newScenes, newName]) => {
    if (maybeCurrentProject.value?.id) {
      updateProject(maybeCurrentProject.value.id, {
        characters: newCharacters,
        scenes: newScenes,
        name: newName
      })
    }
  }, { deep: true })

  const getProjects = async (godProjectId: string | null = null): Promise<void> => {
    if (godProjectId) {
      const projectFromDb = await getSingleProject(godProjectId)
      projects.value = [projectFromDb]
    } else {
      const projectsFromDb = await getProjectsFromDb()
      projects.value = [...projectsFromDb]
    }
  }

  const createProject = async ({ name, projectSettings, characters, moodboardId }: { name: string, projectSettings: ProjectSettings, characters?: Character[], moodboardId?: string }): Promise<Project> => {
    if (projects.value === null) {
      throw new Error('Projects not loaded')
    }

    const axios = await getAxios()

    const response = await axios.post('/api/create-project', {
      name,
      projectSettings,
      characters,
      moodboardId
    })

    await getProjects()

    const newProject = projects.value.find((p) => p.id === response.data.id)

    if (!newProject) {
      throw new Error('New project not found')
    }

    return newProject
  }

  const deleteProject = async (projectId: string): Promise<void> => {
    await deleteProjectFromDb(projectId)

    await getProjects()
  }

  const copyProject = async (projectId: string): Promise<void> => {
    const axios = await getAxios()

    await axios.post('/api/copy-project', { projectId })

    await getProjects()
  }

  const getCurrentProjectShareableUrl = async (): Promise<string> => {
    if (!currentProjectId.value) {
      throw new Error('No current project')
    }
    const axios = await getAxios()

    const response = await axios.post(`/api/project-token?projectId=${currentProjectId.value}`)
    const token = response.data
    return `/link/${currentProjectId.value}/${token}`
  }

  const getShareableProjectLink = async (projectId: string) => {
    const axios = await getAxios()

    const response = await axios.post(`/api/project-token?projectId=${projectId}`)
    const token = response.data
    return `/link/${projectId}/${token}`
  }

  const createMoodboard = async (name: string, images: File[], baseStyle: string): Promise<string> => {
    const axios = await getAxios()

    const formData = new FormData()
    images.forEach((image) => {
      formData.append('files', image)
    })

    formData.append('name', name)
    formData.append('baseStyle', baseStyle)

    const response = await axios.post('/api/create-moodboard', formData)

    return response.data.id
  }

  return {
    projects,
    currentProjectId,
    promisedTimeout,
    voiceoverAllVoices,
    setCurrentProjectId,
    setCurrentProjectIdToDraft,
    getProjects,
    createProject,
    deleteProject,
    copyProject,
    currentProject,
    createFrame,
    updateFrame,
    maybeCurrentProject,
    getCurrentProjectShareableUrl,
    getShareableProjectLink,
    clearDraft,
    createMoodboard,
    getVoiceoverVoices,
    isNewProject
  }
})

// eslint-disable-next-line
if ((import.meta as any).hot) {
  // eslint-disable-next-line
  (import.meta as any).hot.accept(acceptHMRUpdate(useAppStore, (import.meta as any).hot)) // ts-ignore
}
