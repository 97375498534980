import { getAxios } from '@/services/axiosHelper'
import { useToast } from '@/shadcn/components/ui/toast'
import { Organization, PaywallPrice, CheckoutParams } from '@/types'

export async function proceedToCheckout (params: CheckoutParams): Promise<void> {
  const { toast } = useToast()
  const axios = await getAxios()
  const checkoutUrl = '/api/checkout'

  try {
    const response = await axios.get(checkoutUrl, {
      params: {
        customerEmail: params.customerEmail,
        tier: params.tier,
        trial: params.trial,
        seats: params.seats || 5,
        billingPeriod: params.billingPeriod,
        applyIndividualDiscount: params.applyIndividualDiscount || false
      }
    })
    window.location.assign(response.data.redirect_url)
  } catch (error: unknown) {
    const e = error as { response?: { data?: { detail?: string } } }
    if (e.response?.data?.detail) {
      toast({
        title: 'Error',
        description: e.response.data.detail
      })
    } else {
      toast({
        title: 'Error',
        description: 'An error occurred. Please try again later.'
      })
    }
    throw error
  }
}

export async function activateSubscription (): Promise<boolean> {
  const axios = await getAxios()
  try {
    const response = await axios.post('/api/upgrade-to-paid')
    // wait for organization to be updated
    await new Promise((resolve) => setTimeout(resolve, 5000))
    if (response && response.data.success === true) {
      return true
    } else {
      return false
    }
  } catch (error) {
    console.error('Payment failed:', error)
    return false
  }
}

//  Mapping for paywall price to checkout tier
export function mapTierForCheckout (
  tier: PaywallPrice['title'],
  includeVideoAddOn: boolean
): string {
  if (tier === 'Unlimited' && !includeVideoAddOn) {
    return 'Individual'
  } else if (tier === 'Generative Video Studio') {
    return 'Generative Video Studio Max'
  }

  return includeVideoAddOn ? `${tier} + Video Add-on` : tier
}

//  Mapping adjustments for checkout tier for video add-on upsell
export function mapTierForVideoAddonUpsell (
  tier: Organization['tier'],
  includeVideoAddOn: boolean
): string {
  let mappedTier = ''
  if (tier === 'Individual') {
    mappedTier = 'Unlimited'
  } else mappedTier = tier

  return includeVideoAddOn ? `${mappedTier} + Video Add-on` : mappedTier
}
