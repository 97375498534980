const earlyActivationOptions = {
  Essential: {
    title: 'Essential plan',
    price: '$19',
    priceBefore: '$39.00',
    pricePeriod: '3 months',
    discount: '50%',
    features: [
      '500 Image Credits',
      'All creative features',
      '2 users',
      '20 Project Limit',
      '1 Custom Character',
      '20 Panels per Project',
      'ZIP export'
    ],
    yearly: {
      priceBefore: '$348.00',
      price: '$174',
      pricePeriod: 'Billed Annually'
    }
  },
  Pro: {
    title: 'Pro plan',
    price: '$35',
    discount: '10%',
    priceBefore: '$39.00',
    pricePeriod: '3 months',
    features: [
      '1000 Image Credits',
      'Unlimited projects',
      '5 Custom Characters',
      '100+ panels per project',
      'ZIP export',
      'PPT, Video Export'
    ],
    yearly: {
      priceBefore: '$374.00',
      price: '$336.00',
      pricePeriod: 'Billed Annually'
    }
  },
  Individual: {
    title: 'Unlimited plan',
    price: '$89',
    discount: '10%',
    priceBefore: '$99.00',
    pricePeriod: '3 months for 2 users',
    features: [
      'Unlimited image generation',
      'All creative features',
      '2 users',
      'ZIP export',
      'Unlimited projects',
      '10 Custom Characters',
      '200+ panels per project',
      'PPT, Video Export'
    ],
    yearly: {
      priceBefore: '$948.00',
      price: '$853',
      pricePeriod: 'Billed Annually'
    }
  }
}

export type ActivationUserTier = 'Individual' | 'Pro' | 'Essential' | 'Pro + Video Add-on' | 'Unlimited + Video Add-on' | 'Essential + Video Add-on'
export const getOption = (userTier: ActivationUserTier) => {
  if (['Pro + Video Add-on', 'Unlimited + Video Add-on', 'Essential + Video Add-on'].includes(userTier)) {
    if (userTier === 'Essential + Video Add-on') {
      const activationOptions = earlyActivationOptions.Essential

      return {
        ...activationOptions,
        discount: '10%',
        features: [
          '100 Video Credits',
          ...activationOptions.features
        ],
        priceBefore: '$29.00',
        price: '$26.00',
        title: 'Essential + Video Add-on'
      }
    } else if (userTier === 'Pro + Video Add-on') {
      const activationOptions = earlyActivationOptions.Pro

      return {
        ...activationOptions,
        priceBefore: '$59.00',
        price: '$53.00',
        features: [
          '300 Video Credits',
          ...activationOptions.features
        ],
        title: 'Pro + Video Add-on'
      }
    } else if (userTier === 'Unlimited + Video Add-on') {
      const activationOptions = earlyActivationOptions.Individual

      return {
        ...activationOptions,
        priceBefore: '$139.00',
        price: '$125.00',
        features: [
          '700 Video Credits',
          ...activationOptions.features
        ],
        title: 'Unlimited + Video Add-on'
      }
    } else {
      throw new Error('Invalid user tier')
    }
  } else {
    return earlyActivationOptions[userTier as 'Individual' | 'Pro' | 'Essential']
  }
}
