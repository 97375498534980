<template>
  <Dialog
    v-model:open="isModalOpen"
    class="tw-w-[1000px] tw-border-none"
  >
    <DialogContent
      hide-close
      class="tw-min-w-[860px] tw-border-none tw-p-4"
    >
      <div v-if="thankYou">
        <DialogTitle class="tw-text-2xl tw-font-bold">
          Thank you for upgrading!
        </DialogTitle>
        <div class="tw-w-full tw-flex tw-flex-col tw-items-center tw-gap-4">
          <p>Your subscription has been activated.</p>
          <Button
            @click="checkoutStore.closeRunwayUpsellModal()"
          >
            Close and Start Creating
          </Button>
        </div>
      </div>
      <div v-else-if="paymentRejected">
        <DialogTitle class="tw-text-2xl tw-font-bold">
          Payment failed
        </DialogTitle>
        <div class="tw-w-full tw-flex tw-flex-col tw-gap-4">
          <p>Unfortunately, your payment was unsuccessful.</p>
          <p>Please update your payment details.</p>
          <Button
            class="tw-ml-auto tw-bg-white tw-text-black tw-font-bold tw-mt-4"
            :loading="updatePaymentLoading"
            @click="redirectToCustomerPortal"
          >
            Update Payment Method
          </Button>
        </div>
      </div>
      <div
        v-else
        class="tw-w-full"
      >
        <DialogTitle class="tw-sr-only">
          Runway Subscription Upgrade
        </DialogTitle>
        <Alert
          v-if="showAlert"
          class="tw-mt-4 tw-mb-2"
        >
          <AlertDescription>
            {{ alertMessage }}
          </AlertDescription>
        </Alert>
        <img :src="RunwayLogo">
        <div class="tw-flex tw-flex-col tw-gap-4">
          <div class="tw-text-2xl tw-font-bold tw-pt-4 tw-px-4 tw-flex tw-items-end tw-gap-4">
            <span>
              <span class="tw-line-through tw-text-gray-500 tw-text-xl">${{ price }}</span>
              <span class="tw-text-neutral-100 tw-text-2xl tw-mx-2">${{ discountedPrice }}</span>
              <span class="tw-text-sm tw-text-gray-500">/ month</span>
            </span>
            <Button
              class="tw-ml-auto tw-bg-white tw-text-black tw-font-bold tw-mt-4"
              @click="handleAction"
            >
              {{ loading ? checkoutStore.runwayUpsellConfig.loadingText : checkoutStore.runwayUpsellConfig.actionText }}
            </Button>
            <Button
              :disabled="loading"
              class="tw-pl-4 tw-pr-4 !tw-bg-neutral-900 !tw-text-neutral-100 tw-font-bold tw-mt-4 hover:tw-bg-neutral-800"
              @click="continueTrial"
            >
              {{ checkoutStore.runwayUpsellConfig.skipText ?? 'Skip' }}
            </Button>
          </div>
        </div>
      </div>
    </DialogContent>
  </Dialog>
</template>

<script setup lang="ts">
import { Dialog, DialogContent, DialogTitle } from '@/shadcn/components/ui/dialog'
import { Alert, AlertDescription } from '@/shadcn/components/ui/alert'
import { Button } from '@/shadcn/components/ui/button'
import { computed, watch, ref } from 'vue'
import RunwayLogo from '@/assets/runway-upsell.svg'
import { useUserStore } from '@/store/user'
import { useCheckoutStore } from '@/store/checkout'
import tracking from '@/services/tracking'
import { getAxios } from '@/services/axiosHelper'
import { storeToRefs } from 'pinia'
import { useRoute } from 'vue-router'
import { isTrialVideoLimitEnabled } from '@/services/statsig'

const route = useRoute()

const checkoutStore = useCheckoutStore()
const { runwayUpsellModal: isModalOpen } = storeToRefs(checkoutStore)

const userStore = useUserStore()
const loading = ref(false)
const thankYou = ref(false)
const paymentRejected = ref(false)
const updatePaymentLoading = ref(false)

const handleAction = async () => {
  tracking.track('Runway Upsell Modal Confirm Upgrade Clicked: ' + checkoutStore.runwayUpsellConfig.type)
  loading.value = true
  const success = await checkoutStore.runwayUpsellConfig.action()
  loading.value = false
  if (success) {
    thankYou.value = true
    tracking.track('Runway Upsell Modal Confirm Upgrade Success: ' + checkoutStore.runwayUpsellConfig.type)
  } else {
    paymentRejected.value = true
    tracking.track('Runway Upsell Modal Confirm Upgrade Failed: ' + checkoutStore.runwayUpsellConfig.type)
  }
}

// This is the price to upgrade to subscription
const TIER_SUBSCRIPTION_PRICE_MAP = {
  'Unlimited + Video Add-on': { regular: '149', discounted: '125' },
  'Pro + Video Add-on': { regular: '59', discounted: '53' },
  'Essential + Video Add-on': { regular: '29', discounted: '26' }
} as const

const price = computed(() => {
  return TIER_SUBSCRIPTION_PRICE_MAP[userStore.org.tier as keyof typeof TIER_SUBSCRIPTION_PRICE_MAP]?.regular ?? ''
})

const discountedPrice = computed(() => {
  return TIER_SUBSCRIPTION_PRICE_MAP[userStore.org.tier as keyof typeof TIER_SUBSCRIPTION_PRICE_MAP]?.discounted ?? ''
})

const continueTrial = () => {
  tracking.track('Runway Upsell Modal Continue Trial Clicked')
  checkoutStore.closeRunwayUpsellModal()
}

watch(isModalOpen, (value) => {
  if (value) {
    tracking.track('Runway Upsell Modal Opened')
  }
})

async function redirectToCustomerPortal () {
  updatePaymentLoading.value = true
  tracking.track('Runway Upsell Modal Update Payment Method Clicked')
  const axios = await getAxios()
  const result = await axios.post('/api/update-payment-method')
  window.location.assign(result.data.redirect_url)
}

const alertMessage = computed(() => {
  if (route.path.includes('timeline')) {
    return 'You have used up your 3 free trial video generations. View them in the timeline. To create additional videos, you need to activate your subscription.'
  }
  return 'You have used up your 3 free video generations available during your trial. To create additional videos, you need to activate your subscription.'
})

const showAlert = computed(() => {
  return userStore.org?.subscription_status && isTrialVideoLimitEnabled(userStore.org.subscription_status)
})

</script>
