// Composables
import { createRouter, createWebHistory } from 'vue-router'
import { isUserLoggedIn } from '@/auth'

const routes = [
  {
    path: '/link/:projectId/:token',
    name: 'StoryBoardShareFrame',
    component: () => import(/* webpackChunkName: "storyboardshareframe" */ '@/views/StoryBoardShareFrame.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/storyboard/:projectId/edit/:id',
    name: 'StoryBoardEditFrame',
    component: () => import(/* webpackChunkName: "storyboardeditframe" */ '@/views/StoryBoardEditFrame.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/timeline/:projectId/edit/:id',
    name: 'TimelineEditFrame',
    component: () => import(/* webpackChunkName: "storyboardeditframe" */ '@/views/StoryBoardEditFrame.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/',
    component: () => import('@/layouts/default/Default.vue'),
    children: [
      {
        path: '',
        alias: ['/index.html'],
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'storyboard/new',
        name: 'NewStoryBoard',
        component: () => import(/* webpackChunkName: "storyboard" */ '@/views/NewStoryBoard.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'storyboard/:id',
        name: 'StoryBoard',
        component: () => import(/* webpackChunkName: "storyboard" */ '@/views/StoryBoard.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'organization',
        redirect: { name: 'Organization' }
      },
      {
        path: 'team',
        name: 'Organization',
        component: () => import(/* webpackChunkName: "organization" */ '@/views/Organization.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: '/billing',
        name: 'Billing',
        component: () => import('@/views/Billing.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'logout',
        name: 'Logout',
        component: () => import(/* webpackChunkName: "logout" */ '@/views/Logout.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: '/timeline/:projectId',
        name: 'Timeline',
        component: () =>
          import(/* webpackChunkName: "timeline" */ '@/views/Timeline.vue'),
        meta: { requiresAuth: true }
      }
    ]
  },
  {
    path: '/',
    component: () => import('@/layouts/light/Light.vue'),
    children: [
      {
        path: 'register-fhan2tjswwppgczwedhqh3y8cirja0k6',
        redirect: { name: 'Register' }
      },
      {
        path: 'register-0bc9ylevfd2cy035bho8hkuu5w8gsl8x',
        redirect: { name: 'Register' }
      },
      {
        path: 'google-oauth-register',
        component: () => import(/* webpackChunkName: "login" */ '@/views/GoogleRegistration.vue'),
        meta: { requiresAuth: false, theme: 'lightTheme' }
      },
      {
        path: 'saml-callback',
        component: () => import(/* webpackChunkName: "login" */ '@/views/SAMLCallback.vue'),
        meta: { requiresAuth: false, theme: 'lightTheme' }
      },
      {
        path: 'early-access',
        redirect: { name: 'Register' }
      },
      {
        path: 'admin-hdzD3ovnnND5Z2AUbVTyJMDQJjnh849MrEOh7i3dUjRxFPqEeKbHv6gJVk88olnc',
        name: 'Admin',
        component: () => import(/* webpackChunkName: "register" */ '@/views/Admin.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'onboarding',
        name: 'Onboarding',
        component: () => import(/* webpackChunkName: "survey" */ '@/views/Survey.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'survey',
        name: 'Survey',
        component: () => import(/* webpackChunkName: "survey" */ '@/views/Survey.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'appsumo',
        name: 'AppSumo',
        component: () => import(/* webpackChunkName: "appsumo" */ '@/views/AppSumo.vue'),
        meta: { requiresAuth: false, theme: 'lightTheme' }
      },
      {
        path: 'accept-invite',
        name: 'AcceptInvite',
        component: () => import(/* webpackChunkName: "appsumo" */ '@/views/AcceptInvite.vue'),
        meta: { requiresAuth: false, theme: 'lightTheme' }
      },
      {
        path: 'upgrade-to-annual',
        name: 'UpgradeToAnnual',
        component: () => import(/* webpackChunkName: "setpassword" */ '@/views/UpgradeToAnnual.vue'),
        meta: { requiresAuth: true }
      }
    ]
  },
  {
    path: '/',
    component: () => import('@/layouts/Dark.vue'),
    children: [
      {
        path: 'generative-video-studio',
        name: 'GenerativeVideoStudio',
        component: () => import(/* webpackChunkName: "generative-video-studio" */ '@/views/GenerativeVideoStudio.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'payment-done',
        name: 'PaymentDone',
        component: () => import(/* webpackChunkName: "paymentdone" */ '@/views/PaymentDone.vue'),
        meta: { requiresAuth: true }
      }
    ]
  },
  {
    path: '/',
    component: () => import('@/layouts/enter/Enter.vue'),
    children: [
      {
        path: 'login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
        meta: { requiresAuth: false, theme: 'entranceTheme' }
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import(/* webpackChunkName: "register" */ '@/views/Register.vue'),
        meta: { requiresAuth: false, theme: 'entranceTheme' }
      },
      {
        path: 'sso',
        name: 'SSO',
        component: () => import(/* webpackChunkName: "sso" */ '@/views/SSO.vue'),
        meta: { requiresAuth: false, theme: 'entranceTheme' }
      },
      {
        path: 'forgot-password',
        name: 'ForgotPassword',
        component: () => import(/* webpackChunkName: "forgotpassword" */ '@/views/ForgotPassword.vue'),
        meta: { requiresAuth: false, theme: 'lightTheme' }
      },
      {
        path: 'set-password',
        name: 'SetPassword',
        component: () => import(/* webpackChunkName: "setpassword" */ '@/views/SetPassword.vue'),
        meta: { requiresAuth: false, theme: 'lightTheme' }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.onError((error, to) => {
  if (error.message.includes('Failed to fetch dynamically imported module') || error.message.includes('error loading dynamically imported module')) {
    console.error('Failed to fetch dynamically imported module', error, to)
    window.location = to.fullPath
  }
})

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const isAuthenticated = await isUserLoggedIn()

  document.title = 'Katalist'

  if (to.path.startsWith('/link/') || to.path.startsWith('/appsumo')) {
    next()
    return
  }

  if (to.path.startsWith('/generative-video-studio') && !isAuthenticated) {
    localStorage.setItem('redirect', '/generative-video-studio')
    next('/register')
    return
  }

  if (to.path.startsWith('/set-password')) {
    next()
    return
  }

  if (to.path.startsWith('/google-oauth-register')) {
    next()
    return
  }

  if (to.path.startsWith('/saml-callback')) {
    next()
    return
  }

  if (requiresAuth && !isAuthenticated) {
    next('login')
  } else if (!requiresAuth && isAuthenticated) {
    next('')
  } else {
    next()
  }
})

export default router
