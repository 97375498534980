import { defineStore, acceptHMRUpdate } from 'pinia'
import {
  Frame,
  PredictionPayload,
  FrameOption,
  VideoPayload,
  Project,
  GenerationProgress,
  VideoPayloadSVD,
  VideoPayloadRunway,
  VideoPayloadDialogue,
  VideoPayloadVeo2
} from '@/types'
import { computed, ComputedRef, ref, reactive, readonly } from 'vue'
import { useAppStore } from '@/store/app'
import { useRoute } from 'vue-router'
import { useUserStore } from '@/store/user'
import { VIDEO_MODELS } from '@/constants/video'

export interface FrameState {
  generationProgress: GenerationProgress
  videoInProgress: boolean
  imageInProgress: boolean
  inpaintInProgress: boolean
  styleTransferInProgress: boolean
  loadingIndicators: number
  editorImage: string | null
  editorVideo: string | null
  openAIPrompt: PredictionPayload | null
  videoPayload: VideoPayload | null
  videoError?: string
}

interface InternalFrameState {
  videoPayloadByType: {
    dialogue: VideoPayloadDialogue | null
    runway: VideoPayloadRunway | null
    svd: VideoPayloadSVD | null
    veo2: VideoPayloadVeo2 | null
  }
}

interface FrameStateMap {
  [frameId: string]: FrameState
}

interface InternalFrameStateMap {
  [frameId: string]: InternalFrameState
}

export class FrameStateError extends Error {
  constructor (message: string) {
    super(message)
    this.name = 'FrameStateError'
  }
}

const getDefaultFrameOption = (image: string): FrameOption => {
  return {
    image,
    type: 'upload'
  }
}

function getDefaultPayloadByType(
  type: 'svd',
  editorImage: string
): VideoPayloadSVD
function getDefaultPayloadByType(
  type: 'runway',
  editorImage: string
): VideoPayloadRunway
function getDefaultPayloadByType(
  type: 'veo2',
  editorImage: string
): VideoPayloadVeo2
function getDefaultPayloadByType(
  type: 'dialogue',
  editorImage: string,
  characterId: string
): VideoPayloadDialogue
function getDefaultPayloadByType (
  type: 'svd' | 'runway' | 'dialogue' | 'veo2',
  editorImage: string,
  characterId?: string
): VideoPayload {
  switch (type) {
    case 'svd':
      return {
        motionBucketId: 127,
        sourceImage: editorImage,
        type: 'svd'
      } as VideoPayloadSVD
    case 'veo2':
      return {
        videoPrompt: '',
        sourceImage: editorImage,
        type: 'veo2'
      } as VideoPayloadVeo2
    case 'runway':
      return {
        videoPrompt: '',
        videoDuration: 5,
        sourceImage: editorImage,
        type: 'runway'
      } as VideoPayloadRunway
    case 'dialogue':
      return {
        text: '',
        characterId,
        sourceImage: editorImage,
        type: 'dialogue'
      } as VideoPayloadDialogue
  }
}

export const getDefaultPayload = (
  editorImage: string
): VideoPayloadSVD | VideoPayloadRunway => {
  const userStore = useUserStore()

  if (userStore.features.RUNWAY) {
    return getDefaultPayloadByType('runway', editorImage)
  } else {
    return getDefaultPayloadByType('svd', editorImage)
  }
}

export const useEditorStateStore = defineStore('editorState', () => {
  const editorStates = ref<FrameStateMap>({})
  const internalEditorStates = ref<InternalFrameStateMap>({})
  const appStore = useAppStore()
  const editorViewModeState = ref<'storyboard' | 'timeline'>('storyboard')

  const frames = computed(() => appStore.currentProject.frames)
  const initializeProjectState = (project: Project) => {
    project.frames.forEach((frame) => {
      initializeFrameState(frame)
    })
  }

  const initializeFrameState = (frame: Frame) => {
    const editorImage = frame.image!
    const editorVideo = frame.video

    editorStates.value[frame.id] = {
      generationProgress: 'DONE',
      videoInProgress: false,
      imageInProgress: false,
      inpaintInProgress: false,
      styleTransferInProgress: false,
      loadingIndicators: 0,
      editorImage: null,
      editorVideo: null,
      openAIPrompt: null,
      videoPayload: null
    }

    internalEditorStates.value[frame.id] = {
      videoPayloadByType: {
        dialogue: null,
        runway: null,
        svd: null,
        veo2: null
      }
    }

    if (editorImage) {
      updateEditorImage(frame, editorImage)
    }

    if (editorVideo) {
      updateEditorVideo(frame, editorVideo)
    } else {
      setFallbackEditorVideo(frame)
    }
  }

  const updateFrameState = (
    { id: frameId }: { id: string },
    updates: Partial<FrameState>
  ) => {
    const frame = frames.value.find((f) => f.id === frameId)
    if (!frame) {
      throw new Error('Frame not found')
    }
    if (!editorStates.value[frame.id]) {
      initializeFrameState(frame)
    }
    editorStates.value[frame.id] = {
      ...editorStates.value[frame.id],
      ...updates
    }

    if (updates.videoPayload) {
      switch (updates.videoPayload.type) {
        case 'svd':
          internalEditorStates.value[frame.id].videoPayloadByType.svd =
            updates.videoPayload
          break
        case 'runway':
          internalEditorStates.value[frame.id].videoPayloadByType.runway =
            updates.videoPayload
          break
        case 'dialogue':
          internalEditorStates.value[frame.id].videoPayloadByType.dialogue =
            updates.videoPayload
          break
        default:
          break
      }
    }
  }

  const getFrameState = ({
    id: frameId
  }: {
    id: string
  }): ComputedRef<FrameState> =>
    computed(() => {
      if (!editorStates.value[frameId]) {
        const frame = frames.value.find((f) => f.id === frameId)
        if (!frame) {
          throw new Error('Frame not found')
        }
        initializeFrameState(frame)
      }
      return editorStates.value[frameId]
    })

  const videoModeState = ref(false)
  const videoMode = readonly(videoModeState)
  const editorViewMode = readonly(editorViewModeState)

  const switchEditorViewMode = (mode: 'timeline' | 'storyboard') => {
    editorViewModeState.value = mode
    smartSwitcher(mode === 'timeline')
  }

  const enableVideoMode = () => {
    videoModeState.value = true
    smartSwitcher(true)
  }

  const disableVideoMode = () => {
    videoModeState.value = false
    smartSwitcher(false)
  }

  const toggleVideoMode = () => {
    if (videoModeState.value) {
      disableVideoMode()
    } else {
      enableVideoMode()
    }
  }

  const updateEditorImage = (
    { id: frameId }: { id: string },
    image: string
  ) => {
    const frame = frames.value.find((f) => f.id === frameId)!
    const frameOption =
      frame.frameOptions.find((fo) => fo.image === image) ??
      getDefaultFrameOption(image)
    if (!frameOption.payload) {
      // assume it's an upload
      frameOption.payload = {
        creativeDirection: '',
        negativePrompt: '',
        shot: 'Medium shot',
        angle: 'Eye level shot'
      }
    }

    if (!frameOption.payload!.scene) {
      frameOption.payload!.scene = (frame as Frame & { scene: string }).scene
    }

    updateFrameState(frame, {
      editorImage: image,
      openAIPrompt: frameOption.payload
    })
  }

  const updateEditorVideo = (
    { id: frameId }: { id: string },
    video: string
  ) => {
    const frame = frames.value.find((f) => f.id === frameId)!
    const videoOption = frame.videoOptions.find((vo) => vo.url === video)
    if (!videoOption) {
      updateFrameState(frame, {
        editorVideo: video,
        videoPayload: getDefaultPayload(frame.image!)
      })
      videoModel.value = getDefaultPayload(frame.image!).type as VIDEO_MODELS
    } else {
      if (videoOption.payload.type === 'upload') {
        const defaultPayload = getDefaultPayload(frame.image!)
        videoModel.value = defaultPayload.type as VIDEO_MODELS
        updateFrameState(frame, {
          editorVideo: video,
          videoPayload: defaultPayload
        })
      } else {
        updateFrameState(frame, {
          editorVideo: video,
          videoPayload: reactive(videoOption.payload)
        })
        if (videoOption.payload.type === 'dialogue') {
          updateVideoPromptMode(
            frameId,
            'dialogue',
            videoOption.payload.characterId!
          )
        } else {
          if (videoOption.payload.type === VIDEO_MODELS.RUNWAY) {
            videoModel.value = VIDEO_MODELS.RUNWAY
          } else if (videoOption.payload.type === VIDEO_MODELS.SVD) {
            videoModel.value = VIDEO_MODELS.SVD
          } else if (videoOption.payload.type === VIDEO_MODELS.VEO2) {
            videoModel.value = VIDEO_MODELS.VEO2
          }
        }
      }
    }
  }

  function updateVideoPromptMode(frameId: string, value: 'action'): void
  function updateVideoPromptMode(
    frameId: string,
    value: 'dialogue',
    characterId: string
  ): void

  function updateVideoPromptMode (
    frameId: string,
    value: 'action' | 'dialogue',
    characterId?: string
  ) {
    if (value === 'action') {
      const videoPayload =
        internalEditorStates.value[frameId].videoPayloadByType.runway ??
        getDefaultPayloadByType(
          'runway',
          editorStates.value[frameId].editorImage!
        )
      updateFrameState(
        { id: frameId },
        {
          videoPayload
        }
      )
      videoModel.value = VIDEO_MODELS.RUNWAY
    } else if (value === 'dialogue') {
      const videoPayload =
        internalEditorStates.value[frameId].videoPayloadByType.dialogue ??
        getDefaultPayloadByType(
          'dialogue',
          editorStates.value[frameId].editorImage!,
          characterId!
        )
      updateFrameState(
        { id: frameId },
        {
          videoPayload
        }
      )
    }
  }

  const route = useRoute()

  const setFallbackEditorVideo = (frame: Frame) => {
    const defaultPayload = getDefaultPayload(frame.image!)
    updateFrameState(frame, {
      editorVideo: frame.image,
      videoPayload: defaultPayload
    })
    if (defaultPayload.type === 'runway') {
      updateVideoPromptMode(frame.id, 'action')
    }
    videoModel.value = defaultPayload.type as VIDEO_MODELS
  }

  const smartSwitcher = async (newVal: boolean) => {
    if (route.name !== 'StoryBoardEditFrame') {
      return
    }

    const frameId = route.params.id as string

    const editorState = getFrameState({ id: frameId })

    const frame = frames.value.find((f) => f.id === frameId)!
    if (newVal) {
      const correspondingVideoOption = frame.videoOptions?.find(
        (vo) =>
          vo.payload.type !== 'upload' &&
          vo.payload.sourceImage === editorState.value.editorImage
      )
      if (correspondingVideoOption) {
        if (correspondingVideoOption.payload.type === 'upload') {
          setFallbackEditorVideo(frame)
        } else {
          updateEditorVideo(frame, correspondingVideoOption.url)
        }
      } else if (frame.video) {
        updateEditorVideo(frame, frame.video)
      } else if (frame.image) {
        setFallbackEditorVideo(frame)
      } else {
        throw new FrameStateError('No image or video to switch to found')
      }
    } else {
      const correspondingVideoOption = frame.videoOptions?.find(
        (vo) => vo.url === editorState.value.editorVideo
      )

      if (!correspondingVideoOption) {
        return
      }

      const correspondingFrameOption = frame.frameOptions?.find(
        (fo) =>
          correspondingVideoOption?.payload.type !== 'upload' &&
          fo.image === correspondingVideoOption?.payload.sourceImage
      )

      if (correspondingFrameOption && correspondingFrameOption.image) {
        updateEditorImage({ id: frameId }, correspondingFrameOption.image)
      } else if (frame.image) {
        updateEditorImage({ id: frameId }, frame.image)
      } else {
        throw new FrameStateError('No image to switch to found')
      }
    }
  }

  const videoModel = ref<VIDEO_MODELS>(VIDEO_MODELS.RUNWAY)

  const updateVideoModel = (frameId: string, model: VIDEO_MODELS) => {
    videoModel.value = model

    const videoPayload = (() => {
      if (internalEditorStates.value[frameId].videoPayloadByType[model]) {
        return internalEditorStates.value[frameId].videoPayloadByType[model]
      } else if (model === VIDEO_MODELS.SVD) {
        return getDefaultPayloadByType(
          'svd',
          editorStates.value[frameId].editorImage!
        )
      } else if (model === VIDEO_MODELS.RUNWAY) {
        return getDefaultPayloadByType(
          'runway',
          editorStates.value[frameId].editorImage!
        )
      } else if (model === VIDEO_MODELS.VEO2) {
        return getDefaultPayloadByType(
          'veo2',
          editorStates.value[frameId].editorImage!
        )
      } else {
        throw new Error('No video payload found')
      }
    })()

    updateFrameState(
      { id: frameId },
      {
        videoPayload
      }
    )

    console.log('updated video model', videoPayload)
  }

  return {
    editorStates,
    initializeProjectState,
    initializeFrameState,
    updateFrameState,
    updateEditorImage,
    updateEditorVideo,
    getFrameState,
    updateVideoPromptMode,
    videoMode,
    enableVideoMode,
    disableVideoMode,
    switchEditorViewMode,
    toggleVideoMode,
    videoModel,
    updateVideoModel,
    editorViewMode
  }
})

// eslint-disable-next-line
if ((import.meta as any).hot) {
  // eslint-disable-next-line
  ;(import.meta as any).hot.accept(
    // eslint-disable-next-line
    acceptHMRUpdate(useEditorStateStore, (import.meta as any).hot)
  )
}
