<template>
  <div class="tw-text-center">
    <div class="tw-p-8 tw-bg-neutral-900 tw-rounded-lg tw-max-h-[90vh] tw-overflow-y-auto">
      <h2 class="tw-text-2xl tw-font-bold tw-mb-6">
        Turn Storyboards into Videos
      </h2>

      <div class="tw-mb-8">
        <video
          src="@/assets/runway-example.mp4"
          alt="Runway video example"
          class="tw-w-full tw-rounded-lg"
          autoplay
          muted
          loop
        />
      </div>

      <div class="tw-bg-[#FF92C8] tw-rounded-lg tw-p-1 tw-mb-8 tw-w-fit tw-px-2 tw-mr-auto">
        <p class="tw-text-black tw-text-sm tw-font-bold">
          Only Available as a Video Add-On!
        </p>
      </div>

      <div class="tw-mb-8">
        <div class="tw-flex tw-items-center tw-mb-4">
          <v-icon
            color="#FF92C8"
            class="tw-mr-2"
          >
            mdi-check-circle
          </v-icon>
          <span>{{ selectedPlan === 'Essential' ? 'SVD' : 'Runway Gen-3' }}</span>
        </div>
        <div class="tw-flex tw-items-center tw-mb-4">
          <v-icon
            color="#FF92C8"
            class="tw-mr-2"
          >
            mdi-check-circle
          </v-icon>
          <span>Lip-sync</span>
        </div>
      </div>

      <div class="tw-text-2xl tw-font-bold tw-font-serif tw-mb-8 tw-text-left">
        + ${{ price }} for {{ credits }} credits / month
      </div>

      <div class="tw-flex tw-w-full tw-justify-center tw-gap-4">
        <Button
          variant="outline"
          class="tw-w-full tw-border-neutral-100 tw-border-1 tw-border-solid"
          :disabled="loading"
          @click="proceedWithoutVideoAddOn"
        >
          <Loader
            v-if="loadingWithout"
            class="tw-w-4 tw-h-4 tw-animate-spin"
          />
          <span v-else>
            No, I am good
          </span>
        </Button>
        <Button
          class="tw-w-full"
          :disabled="loading"
          @click="addVideoAddOn"
        >
          <Loader
            v-if="loadingWith"
            class="tw-w-4 tw-h-4 tw-animate-spin"
          />
          <span v-else>
            Bundle it
          </span>
        </Button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, PropType, ref } from 'vue'
import { PaidPlan } from '@/types'
import { Button } from '@/shadcn/components/ui/button'
import { Loader } from 'lucide-vue-next'

const props = defineProps({
  selectedPlan: {
    type: String as PropType<PaidPlan>,
    required: true,
    default: 'Essential'
  }
})

const price = computed(() => {
  switch (props.selectedPlan) {
    case 'Essential':
      return 10
    case 'Pro':
      return 20
    case 'Unlimited':
      return 40
    default:
      return 10
  }
})
const loading = computed(() => {
  return loadingWith.value || loadingWithout.value
})
const loadingWith = ref(false)
const loadingWithout = ref(false)
const credits = computed(() => {
  switch (props.selectedPlan) {
    case 'Essential':
      return '700'
    case 'Pro':
      return '1500'
    case 'Unlimited':
      return '3000'
    default:
      return ''
  }
})

const emit = defineEmits(['proceed-to-checkout', 'close'])

function proceedWithoutVideoAddOn () {
  loadingWithout.value = true
  emit('proceed-to-checkout', false)
}

function addVideoAddOn () {
  loadingWith.value = true
  emit('proceed-to-checkout', true)
}

</script>
